@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  /* overflow-x: hidden; */
  padding: 0;
  margin: 0;
  box-sizing: border-box !important;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth !important;
}

:root {
  --textColor: rgba(255, 255, 255, 1);
  --textBlueColor: rgba(27, 20, 100, 1);
}

.hero-container {
  background-image: url("./Utils/Assets/heroBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  height: 840px;
  /* height: auto; */
  /* background-color: red; */
  /* height: auto !important;; */
}
.mobileMenu {
  display: none !important;
}
.container {
  /* max-width: 1400px; */
  width: 90%;
  /* width: ; */
  margin-left: auto;
  margin-right: auto;
}
.header-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--textColor);
  padding-top: 16px;
  padding-bottom: 20px;
}
.header-box img {
  width: 130.37px;
  height: 70px;
}
.header-nav ul,
.header-nav1 ul {
  display: flex;
  gap: 20px;
}
.header-box ul li {
  text-decoration: none;
  list-style: none;
  text-transform: capitalize;
  cursor: pointer;
  color: white !important;
}
.header-nav .links {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  color: white !important;
  text-decoration: none !important;
  list-style: none;
  text-transform: capitalize;
  cursor: pointer;
}
.header-nav1 .links {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  color: black !important;
  text-decoration: none !important;
  list-style: none;
  text-transform: capitalize;
  cursor: pointer;
}
.contact-btn button {
  text-transform: capitalize;
  border: 0;
  background-color: var(--textColor);
  padding: 12px 20px;
  border-radius: 4px;
  color: var(--textBlueColor) !important;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.contact-btn1 button {
  text-transform: capitalize;
  border: 0;
  background-color: var(--textBlueColor);
  padding: 12px 20px;
  border-radius: 4px;
  color: white !important;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

/* Hero style */
.herobtnpics {
  position: relative;
}
.herobtnpics {
  margin-top: 30px;
  margin-bottom: 50px;
  /* background-color: red; */
}
.heroimg1 {
  position: absolute;
  left: 30%;
  top: 20%;
}
.heroimg2 {
  position: absolute;
  right: 30%;
  bottom: 0%;
}
.hero-heading {
  font-size: 70px;
  text-align: center;
  text-align: center;
  color: var(--textColor);
}
.hero-heading img {
  width: 80px;
  height: 80px;
}

.hero_groups img {
  width: 21% !important;
}

.hero_groups {
  bottom: -20%;
  left: 0%;
  right: 0%;
  position: absolute;
  margin: 0 auto;
  width: 93% !important;
  justify-content: space-between !important;
  display: flex;
}
.hero-para {
  text-align: center !important;
  margin: 0 auto;
  color: white;
  font-size: 20px;
  letter-spacing: 1px;
  word-spacing: 5px;
  padding: 20px 0px;
  width: 80%;
  line-height: 35px;
}

.hero-btn {
  text-align: center !important;
  margin: 0 auto;
  color: white;
  font-size: 20px;
  letter-spacing: 1px;
  word-spacing: 5px;
  /* padding: 20px 0px; */
  padding-bottom: 40px;
}

/* ourpartner
 */

.ourpartner {
  /* margin-top: 150px; */
  margin-top: 220px;
  margin-bottom: 40px;
  /* background-color: #790000; */
  background-color: #f8f8f8;
  padding: 12px;
  border-radius: 20px;
}
.counter {
  display: flex;
  background-color: white;
  border-radius: 5px;
  justify-content: space-between;
}
.counter .counters {
  width: 25%;
  text-align: center;
  padding: 42px;
}
.counter .counters:nth-child(-n + 3) {
  width: 25%;
  text-align: center;
  padding: 42px;
  border-right: 2px solid #e2e2e2;
}
.counter .counters h2 {
  padding-bottom: 12px;
  font-size: 36px;
  color: #1b1464;
  font-weight: 800;
}

.counter .counters p {
  font-weight: 500;
  color: #09071d;
}

.ourpartner h1 {
  text-align: center;
  padding: 30px 0px;
  color: #1b1464;
}
.ourTechPartners1 {
  gap: 10px;
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
}
.ourTechPartners2 {
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  width: 80%;
  margin: 0 auto;
}
.ourpartnerslogos {
  transition: all ease-in-out 0.9s;
  height: 37px !important;
  width: 120px !important;
  object-fit: scale-down;
  cursor: pointer;
}
.ourpartnerslogos {
  transition: all ease-in-out 0.9s;
}
/* .ourpartnerslogos {
  transition: all ease-in-out 0.5s;
}
.ourpartnerslogos:hover {
  transform: scale(1.02);
  filter: brightness(70%);
  transition: all ease-in-out 0.5s;
} */
/* aboutUs */
.aboutuscardsimgs {
  position: relative;
}
.Rectangle1 {
  position: absolute;
  left: 14.8%;
  transform: scaleX(-1);
  width: 36%;
  height: 200px;
  bottom: 10;
}
.Rectangle2 {
  top: -10%;
  bottom: 20px;
  /* background-color: red; */
  position: absolute;
  right: 14.8%;
  transform: scaleX(-1);
  width: 36%;
  height: 200px;
}
.aboutUs {
  /* background-color: red; */
  margin-top: 70px;
  margin-bottom: 250px;
}

.aboutUs h1 {
  color: #1b1464;
  font-weight: 800;
}
.aboutUs p {
  color: #09071d;
  line-height: 27px;
  font-weight: 500;
  padding: 12px 0px;
}
.aboutUsCards {
  position: relative !important;
  display: flex;
  justify-content: space-between;
  /* background-color: #f8f8f8; */
  text-align: center;
  padding: 30px 0px;
}
.aboutUsCards h2 {
  padding-bottom: 20px;
  color: #09071d;
}

.cards {
  width: 30%;
  padding: 40px 80px;
  /* background-color: red; */
  border-radius: 20px;
}

.card1 {
  background-color: #f8f8f8;
}
.card2 {
  position: absolute;
  top: 100px;
  left: 35%;
  right: 35%;
  background-color: #f8f8f8;
}

.card3 {
  position: absolute;
  top: 200px;
  right: 0%;
  background: #1b1464;
}
.card3 h2,
.card3 p {
  color: white !important;
}
.card3 button {
  padding: 16px 22px;
  border-radius: 16px;
  border: 2px solid white;
  background-color: black;
  color: white;
  font-weight: 700;
  position: absolute;
  bottom: -27px;
  left: 30%;
  right: 30%;
  /* .slider-container */

  .slider-container {
    display: flex;
    justify-content: space-between;
  }
  .button-wrapper {
    display: flex;
    gap: 20px;
  }

  .slider-container button {
    text-align: center !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    background-color: #f8f8f8;
    padding: 16px 22px;
    border-radius: 100px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    /* background-color: red; */
    border: none;
    text-wrap: nowrap !important;
    transition: all ease-in-out 0.6s;
  }
  .slider-container button:hover {
    background-color: #1b1464;
    transition: all ease-in-out 0.6s;
    color: white;
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
    margin: 0 auto !important;
    width: 75% !important;
  }

  cursor: pointer;
}

.aboutuscontact {
  text-decoration: none !important;
  color: white !important;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
}
/* ourservices */

/* .slider-container */

.slider-container {
  display: flex;
  justify-content: space-between;
}
.button-wrapper {
  display: flex;
  gap: 20px;
}

.slider-container button {
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  background-color: #f8f8f8;
  padding: 16px 22px;
  border-radius: 100px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  /* background-color: red; */
  border: none;
  text-wrap: nowrap !important;
  transition: all ease-in-out 0.6s;
}
.slider-container button:hover {
  background-color: #1b1464;
  transition: all ease-in-out 0.6s;
  color: white;
}
.button-wrapper {
  display: flex;
  justify-content: center;
  margin: 0 auto !important;
  width: 75% !important;
}

.ourservices {
  margin-top: 70px;
  margin-bottom: 70px;
}
.ourservices h1 {
  color: #1b1464;
  font-weight: 800;
}
.ourservices p {
  color: #09071d;
  line-height: 27px;
  font-weight: 500;
  padding: 12px 0px;
}

.ourServicescard {
  display: flex;
  /* width: 100% !important; */
  justify-content: space-between;
}

.ourservicescards {
  width: 30% !important;
  margin: 30px 0px;
  /* padding: 12px; */
}

.service1 h2 {
  background-color: #f8f8f8;
  color: #09071d;
  border-radius: 20px;
  padding: 16px;
  transition: all ease-in-out 0.5s;
  margin-bottom: 16px;
}
.accordion-item {
  border: none !important;
}
.accordion-button:not(.collapsed) {
  background-color: #1b1464 !important;
  color: white !important;
  border-radius: 10px !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button::after {
  width: 0 !important;
}

/* active button style  */
.activeButton {
  background-color: #1b1464;
  cursor: pointer;
  transition: all ease-in-out 0.5s;
  color: white;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 16px;
}
.service1 h2:hover {
  background-color: #1b1464;
  cursor: pointer;
  transition: all ease-in-out 0.5s;
  color: white;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 16px;
}
.service2 {
  border-radius: 20px;
  background-color: #1b1464;
  padding: 12px;
}
.service2 .service2img {
  width: 100%;
  margin-bottom: 12px;
  /* height: 61%; */
}
.service2 h2 {
  font-weight: 800;
  color: white;
}
.service2 p {
  color: white;
  line-height: 27px;
  font-weight: 500;
  padding: 12px 0px;
}
.service2 button {
  width: 100%;
  padding: 16px 22px;
  border-radius: 10px;
  background: none;
  color: white;
  border: 2px solid white;
  font-weight: 700;
  cursor: pointer;
}
.service3 h2 {
  background-color: #f8f8f8;
  color: #09071d;
  border-radius: 20px;
  padding: 16px;
  transition: all ease-in-out 0.5s;
  margin-bottom: 16px;
}

.service3 h2:hover {
  background-color: #1b1464;
  cursor: pointer;
  transition: all ease-in-out 0.5s;
  color: white;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 16px;
}
.servicescontactus {
  color: white !important;
  text-decoration: none !important;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
}
/* ourwork */
.ourwork {
  background-color: #1b1464;
  padding: 16px;
  border-radius: 20px;
  margin-top: 70px;
  margin-bottom: 70px;
}
.ourworktop {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
}
.ourworktop h1 {
  color: white;
  font-size: 36px;
  font-weight: 800;
  line-height: 43.2px;
  text-align: left;
}
.ourworktop p {
  color: white;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
}
.ourworktop button {
  width: 100%;
  padding: 16px 22px;
  border-radius: 10px;
  background: white;
  color: black;
  border: 2px solid white;
  font-weight: 700;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
}
.ourworkcontactus {
  text-decoration: none !important;
}
.ourworkcards {
  position: relative;
  /* margin: 0 auto !important; */
}
.ourworkcards img {
  display: flex;
  justify-content: center;
  margin: 0 auto !important;
}
.ourworkfirstcards {
  display: flex;
  justify-content: space-between;
}
.ourwork2_1,
.ourwork5_1 {
  display: none !important;
}
.ourwork1 {
  width: 30%;
  height: 770px;
  /* height: 100%; */
  /* background-color: #7abce2; */
  border-radius: 16px;
}
.ourwork1 img {
  height: 770px;
  width: 100%;
}
.ourwork2 {
  height: 385px;
  width: 65%;
  background-color: #f4f5f7;
  border-radius: 16px;
}

/* width: 370px;
height: 370px; */
/* .ourworksecondcards {
  background-color: #1b1464;
  position: absolute;
  left: 35%;
  border: 2px dashed white;
  top: 200px;
  right: 35%;
  top: 37%;
  bottom: 37%;
  border-radius: 16px;
} */
.ourwork3 {
  background-color: #1b1464;
  position: absolute;
  left: 35%;
  border: 2px dashed white;
  top: 200px;
  right: 35%;
  top: 37%;
  bottom: 37%;
  border-radius: 16px;

  padding: 12px;
  /* position: absolute; */
  text-align: center;
  margin: a auto;
  /* background-color: red; */
}

.ourwork3 img {
  width: 100px;
}
.ourwork3 h2,
.ourwork3 p,
.ourwork3 button {
  color: white;
}
.ourwork3 h2 {
  font-size: 36px;
  font-weight: 400;
}
.ourwork3 p {
  color: white;
  line-height: 27px;
  font-weight: 500;
  padding: 12px 0px;
}
.ourwork3 button {
  width: 100%;
  padding: 16px 22px;
  border-radius: 10px;
  background: none;
  color: white;
  border: 2px solid white;
  font-weight: 700;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
}
.ourworkmoreprojects {
  text-decoration: none !important;
  color: white !important;
}
.ourworkthirdcards {
  display: flex;
  /* background-color: rgb(13, 151, 236); */
  justify-content: space-between;
  position: relative;
  margin-top: 5%;
  /* top: 30%; */
}

.ourwork4 {
  height: 385px;
  width: 65%;
  background-color: #f0e7dd;
  border-radius: 16px;
}

.ourwork5 {
  position: absolute;
  width: 30%;
  height: 770px;
  /* height: 100%; */
  /* background-color: #f0e7dd; */
  right: 0;
  bottom: 0;
  border-radius: 16px;
}
.ourwork5 img {
  width: 100%;
  height: 770px;
  border-radius: 16px;
}
/* ourteamCards */
.ourteam {
  margin-top: 70px;
  margin-bottom: 70px;
}
.ourTeamSlider {
  display: flex !important;
  justify-content: space-between;
}
.ourteamTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.ourteamTop h1 {
  font-size: 36px;
  font-weight: 800;
  line-height: 43.2px;
  text-align: left;
}
.ourteamTop p {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
}

.ourteamTop button {
  /* background-color: #a9a9a9; */
  /* padding: 25px;  */
  border: none;
  cursor: pointer;
  margin-left: 20px;
  border-radius: 50%;
}
.ourTeamSlider {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  transition: transform 0.6s ease;
}
.sliderWrapper {
  gap: 20px;

  display: flex;
  animation: slideAnimation 0.5s ease;
}

@keyframes slideAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.ourteamCards {
  flex: 0 0 25%;
  gap: 10px;
  background-color: #fcfcfc;
  /* margin-right: 5px; */
}
.ourteamCards h2 {
  font-size: 16px;
  padding: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: center;
}
/* .ourTeamSlide {
  gap: 20px; 
} */

.ourTeamSlider::-webkit-scrollbar {
  display: none;
}
.ourteamCards {
  position: relative;
}
.ourteamname {
  bottom: 4%;
  left: 18%;
  right: 18%;
  position: absolute;
  /* background-color: red; */
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: center;
}

.ourteampic {
  /* text-align: center ; */
  border-radius: 20px;
  cursor: pointer;
  width: 98%;
  transition: all ease-in-out 0.7s;
}
.ourteampic:hover {
  transform: scale(0.96);
  transition: all ease-in-out 0.7s;
}

/* clientreviews */
.clientreviewscards {
  display: flex;
  justify-content: start !important;
}

.clientreviewcardsbottom {
  margin: 20px 0px;
  background-color: #f8f8f8;
  border-radius: 20px;
  width: 23%;
  padding: 20px;
  cursor: pointer;
}

.clientreviewdetails {
  display: flex;
  align-items: center;
}

.clientmessage {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding: 16px 0px;
}
.clientreviewdetails h3 {
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}
.clientreviewdetails p {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.clientreviewtop {
  margin-bottom: 20px;
}
.clientreviewtop h1 {
  font-size: 36px;
  font-weight: 800;
  line-height: 43.2px;
  text-align: left;
}
.clientreviewtop p {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
}

/* contactUs */
.contactUs {
  padding: 24px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;

  margin-top: 70px;
  margin-bottom: 70px;
  background: url("./Utils//Assets/heroBg.png");
}

.contactInfo {
  width: 45%;
}
.contactInfoTop {
  padding-bottom: 20px;
}
.contactInfoTop h1 {
  color: white;
  font-size: 36px;
  font-weight: 800;
  line-height: 43.2px;
  text-align: left;
}

.contactInfoTop p {
  color: white;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
}

.contactIcons {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: white;
}

.contactIcons .contactRadius {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ffffff;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.contactIcons .contactDetail h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  padding-bottom: 4px;
}
.contactIcons .contactDetail h2 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

.staysocials h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  padding-bottom: 4px;
  color: white;
}

.staysocialicons {
  display: flex;
  gap: 12px;
  cursor: pointer;
  margin-top: 12px;
}
.staysocialicons img {
  width: 40px;
  transition: all ease-in-out 0.6s;
}
.staysocialicons img:hover {
  transform: scale(1.1);
  transition: all ease-in-out 0.6s;
}
.contactForm {
  background-color: white;
  width: 50%;
  box-shadow: 0px -1px 60px 0px #00000014;
  padding: 20px;
  border-radius: 24px;
}

.contactForm .contactTopRow {
  margin-bottom: 20px;
}
.contactForm .contactTopRow h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;
}
.contactForm .contactTopRow p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
}
.form-group input,
.form-group textarea {
  width: 100%;
  margin-bottom: 12px;
  padding: 18px;
  border-radius: 12px;
  font-size: 16px;
  /* border: 1px solid #09071d; */
  border: none !important;
  background-color: #f8f8f8;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  color: #09071d;
}

.contactUsbtn {
  padding: 16px 22px;
  border-radius: 8px;
  border: none;
  background-color: #1b1464;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: left;
}

/* footer */
.footer {
  margin-top: 70px;
  margin-bottom: 70px;
}
.footerVelox {
  padding: 70px;
  text-align: center;
  border-radius: 40px;
  background: #f8f8f8;
}
.footerVelox p {
  /* background-color: red; */
  padding-top: 40px;
  margin: 0 auto;
  width: 70%;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
}

.footer button {
  margin: 20px 0px;
  padding: 20px;
  width: 100%;
  border-radius: 110px;
  border: 1px solid #1b1464;
  color: white;
  background: #1b1464;
  /* cursor: pointer; */
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

/* project  */
/* gallerytopbtns
gallerybottombtns
gallerymiddlebtns */
.gallery {
  margin-top: 70px;
  margin-bottom: 70px;
}
.gallerytop {
  background: url("./Utils/Assets/heroBg.png");
  text-align: center;
  border-radius: 50px;
  padding: 90px;
  color: white;
  margin-bottom: 50px;
}
.gallerytop h1 {
  font-size: 36px;
  font-weight: 800;
  line-height: 54px;
  text-align: center;
  width: 50%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.gallerytop p {
  font-size: 20px;
  width: 50%;
  margin: 0 auto;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
}
.gallerytopbtns,
.gallerymiddlebtns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 12px !important;
}
.gallerybottombtns {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
  gap: 12px !important;
}
.gallerybottombtns button {
  /* width: 100% !important; */
  /* width: 16% !important; */
  text-align: center !important;
}
.gallerytopbtns button,
.gallerymiddlebtns button {
  width: 100% !important;
}
.gallerytopbtns button,
.gallerymiddlebtns button,
.gallerybottombtns button {
  /* width: 16% !important; */
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  background-color: #f8f8f8;
  padding: 16px 22px;
  border-radius: 100px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  /* background-color: red; */
  border: none;
  transition: all ease-in-out 0.6s;
}
.gallerytopbtns button:hover,
.gallerymiddlebtns button:hover,
.gallerybottombtns button:hover {
  background-color: #1b1464;
  transition: all ease-in-out 0.6s;
  color: white;
}

.galleryContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.gallery-item {
  padding: 40px;
  border-radius: 20px;
  cursor: pointer;
  transition: all ease-in-out 0.5s;
}
.gallery-item:hover {
  transform: scale(0.95);
  transition: all ease-in-out 0.5s;
}

.gallery-item img {
  width: 100%;
}

.loadmore {
  margin-top: 50px;
  text-align: center;
}
.loadmore button {
  background-color: #09071d;
  padding: 16px 22px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  border: none;
  transition: all ease-in-out 0.6s;
}

.clientreviewslide1 {
  min-width: 20% !important;
  background-color: rgb(74, 9, 9) !important;
}

/* ======================= updated css  */

/* modelprojectview */
.gallery {
  position: relative;
}
.closebuttonmodel {
  position: absolute;
  top: -84px;
  right: -6px;
}
.closebuttonmodel {
  border: none;
  padding: 6px 7px;
  border-radius: 50% !important;
}
.modelprojectview {
  position: fixed;
  /* top: 0; */
  /* left: 0; */
  z-index: 999;
  height: 100vh;
  overflow: scroll;
}
.modelinner {
  z-index: 999;
}
.modelTopBar {
  /* background-color: #FFFFFF; */
  padding: 12px 0px;
  margin-bottom: 40px;
}
.modelinner {
  background-color: #ffffff;
  border-radius: 20px !important;
  padding: 12px !important;
}
.modelTopBarleft {
  width: 45% !important;
}
.modelTopBarleft img {
  width: 100% !important;
  height: 100% !important;
}
.modelTopBarright {
  width: 54% !important;
}
.modelTopBar {
  /* gap: 30px !important; */
  display: flex;
  justify-content: space-between;
  align-items: center !important;
}

.modelTopBarright h1 {
  font-size: 24px;
  font-weight: 800;
  line-height: 28.8px;
  text-align: left;
  padding: 12px 0px !important;
}

.modelTopBarright p {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
}

.modelTopBarright button {
  padding: 8px 16px;
  border-radius: 100px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  border: none;
  margin-right: 12px;
  color: #1b1464;
  background: #1b14641a;
}
.modelTopBarrightbutton {
  margin: 12px 0px;
}

.modelTopBarrightlink a {
  color: #1b1464 !important;
}

.modelMiddlebar1 img {
  width: 100% !important;
  width: auto;
}

@media (max-width: 850px) {
  .modelTopBar {
    flex-direction: column-reverse !important;
    gap: 30px !important;
  }
  .modelTopBarleft {
    width: 100% !important;
  }
  .modelTopBarleft img {
    width: 100% !important;
    height: 100% !important;
  }
  .modelTopBarright {
    width: 100% !important;
  }
}

@media (max-width: 450px) {
  .modelTopBarrightbutton button {
    margin-bottom: 10px !important;
  }
}
.dark-background {
  background-color: red !important;
}
