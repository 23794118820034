.clientreviewsmall {
  display: none;
}
@media (max-width: 1550px) {
  .hero_groups img {
    width: 25% !important;
  }
}
@media (max-width: 1258px) {
  .hero-container {
    width: 100%;
    height: 900px;
  }
  .hero_groups img {
    width: 25% !important;
  }
}
/* Media queries for 1050px */
@media (max-width: 1050px) {
  .hero-container {
    width: 100%;
    height: 600px;
  }
  .hero-heading {
    font-size: 34px;
    font-weight: 800;
    line-height: 43.6px;
    text-align: center;
  }
  .hero-para {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }
  .hero-alert {
    display: none !important;
  }
  .herobtnpics {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .hero_groups {
    flex-wrap: wrap;
  }
  .hero_groups img {
    width: 50%;
  }
  .ourpartner {
    margin-top: 80px;
    margin-bottom: 40px;
    background-color: #f8f8f8;
    padding: 12px;
    border-radius: 20px;
  }
  /* about us  */
  .stylishBorderAbout {
    display: none !important;
  }
  .aboutUs,
  /* .ourpartner, */
  .ourservices,
  .ourwork,
  .ourteam,
  .clientReviewslide,
  .contactUs,
  .footer {
    /* background-color: red; */
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .ourpartner {
    margin-top: 130px;
    margin-bottom: 40px;
  }
  .aboutUsCards {
    gap: 20px;
    flex-direction: column !important;
  }
  .cards {
    position: static;
    width: 100%;
  }
  .card3 button {
    padding: 16px 22px;
    border-radius: 16px;
    border: 2px solid white;
    background-color: black;
    color: white;
    font-weight: 700;
    position: absolute;
    bottom: 0px;
    left: 30%;
    right: 30%;
    cursor: pointer;
  }

  /* .ourwork  */
  .ourworktop button {
    display: none !important;
  }
  .ourworkfirstcards {
    flex-direction: column;
    gap: 20px !important;
  }
  .ourwork1 {
    display: none;
  }
  .ourwork2 {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100%;
  }

  .ourwork2_1,
  .ourwork5_1 {
    display: block !important;
  }
  .ourwork2_1 {
    background: #0c1930;
    border-radius: 16px;
    height: 385px !important;
    display: flex !important;
    justify-content: center !important ;
    align-items: center !important;
  }

  .ourworkfirstcards {
    flex-direction: column;
    gap: 12px;
  }
  .ourwork3 {
    position: static;
    margin-top: 20px;
    width: 100%;
  }
  .ourworkthirdcards {
    margin-top: 20px !important;
    flex-direction: column !important;
    gap: 20px !important;
  }
  .ourwork4 {
    position: static;
    width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100%;
  }

  .ourwork5_1 {
    width: 100%;
    background-color: #84a4a2;
    border-radius: 16px;
    height: 385px;
    padding: 12px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .ourwork5 {
    display: none !important;
  }
}

/* Media queries for 1000px */
@media (max-width: 1000px) {
  .gallerytopbtns,
  .gallerymiddlebtns,
  .gallerybottombtns {
    flex-wrap: wrap;
  }
  .gallerytopbtns button,
  .gallerymiddlebtns button,
  .gallerybottombtns button {
    text-align: center !important;
    display: flex;
    /* gap: 0px !important; */
    justify-content: center;
    align-items: center !important;
    width: 23% !important;
    flex-wrap: wrap;
  }
}

/* Media queries for 768px */
@media (max-width: 768px) {
  .gallery {
    margin: 0 !important;
  }
  .hero-container {
    width: 100%;
    height: 700px;
  }
  .hero_groups img {
    width: 50% !important;
  }
  .hero_groups {
    justify-content: start;
    bottom: -29% !important;
  }
  .hero-container {
    width: 100%;
    height: 870px;
  }
  .header-nav1,
  .contact-btn1 {
    display: none !important;
  }
  .justForcurson {
    cursor: pointer;
  }
  .hero-btn {
    padding: 0px !important;
  }
  .hero-heading {
    position: relative;
    font-size: 18px;
    font-weight: 800;
    line-height: 43.6px;
    text-align: center;
  }
  .herotopimg1 {
    position: absolute;
    left: -5% !important;
    top: -12px;
  }
  .transformingidea {
    width: 40px !important;
    height: 40px !important ;
  }
  .herotopimg2 {
    top: -12px;
    position: absolute;
    right: -5% !important;
  }
  .header-nav,
  .contact-nav {
    display: none !important;
  }
  .mobileMenu {
    display: block !important;
  }
  .menu-btn {
    padding: 0;
    border: 0;
    background: none !important;
  }
  .menu-btn img {
    width: 35px !important;
    height: 35px !important;
  }
  .mobile-menu {
    z-index: 9 !important;
    /* margin-left: auto !important; */
    position: absolute;
    background-color: #070432;
    width: 100% !important;
    height: 100%;
    top: 0% !important;
    left: 0% !important;
    right: 0% !important;
    margin: 0 auto !important;
  }
  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  .mobileMenu ul li,
  .mobileMenu ul li link,
  .links {
    color: white !important;
    list-style: none !important;
    padding-top: 20px;
    text-decoration: none !important;
  }
  .close-btn {
    float: right;
    padding: 12px !important;
  }
  .closebar {
    filter: invert(100%);
  }

  /* ourpartner */
  .ourpartner {
    /* margin-top: 150px; */
    margin-top: 280px;
    margin-bottom: 40px;
    /* background-color: #790000; */
    background-color: #f8f8f8;
    padding: 12px;
    border-radius: 20px;
  }
  /* counters  */
  .counter {
    display: flex;
    background-color: white;
    border-radius: 5px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .counter .counters {
    width: 50% !important;
    text-align: center;
    padding: 42px;
  }
  .counter2 {
    border-right: none !important;
    border-bottom: 2px solid #e2e2e2;
  }
  .counter1 {
    border-bottom: 2px solid #e2e2e2;
  }
  .cout .counter .counters h2 {
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
    text-align: center;
  }

  .counter .counters p {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
  }
  .ourteamCards {
    flex: 0 0 50%;
  }
  .ourteamprevnextbtns {
    display: flex;
  }
  .ourteamprevnextbtns button img {
    width: 50px !important;
  }
  .clientreviewsmall {
    display: block !important;
  }
  .clientreviewlarge {
    display: none !important;
  }
  .clientreviewcard {
    padding: 12px;
    background-color: #f8f8f8;
    border-radius: 12px;
    width: 100% !important;
  }
  .clientmessage {
    padding-bottom: 16px !important;
    text-wrap: wrap !important;
  }
  .reviewStyleimg {
    display: flex;
    justify-content: start !important;
    margin-right: auto !important;
  }
  .clientreviewsbuttons {
    margin-top: 20px;
  }
  .clientreviewsbuttons button {
    margin: 10px;
    border: 0 !important;
    background: none !important;
    cursor: pointer !important;
  }
  /* contact us  */
  .contactUs {
    gap: 20px;
    flex-direction: column-reverse !important;
  }
  .contactForm,
  .contactInfo {
    width: 100% !important;
  }
  /* gallery  */
  .gallerytopbtns,
  .gallerymiddlebtns,
  .gallerybottombtns {
    flex-wrap: wrap;
    margin-bottom: 0px !important;
  }
  .gallerytopbtns button,
  .gallerymiddlebtns button,
  .gallerybottombtns button {
    text-align: center !important;
    display: flex;
    justify-content: center;
    align-items: center !important;
    width: 31% !important;
    flex-wrap: wrap;
  }
  .galleryContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .gallerytop h1 {
    width: 90% !important;
    margin: 0 auto;
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
    text-align: center;
  }
  .gallerytop p {
    width: 90% !important;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }
}

/* Media queries for 650px */
@media (max-width: 650px) {
  .slider-container button {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    text-align: left;
    padding: 12px 26px;
  }
}

/* Media queries for 590px */
@media (max-width: 590px) {
  .ourTechPartners1,
  .ourTechPartners2 {
    width: 100% !important;
    justify-content: center;
    gap: 10px;
  }
  .ourpartnerslogos {
    transition: all ease-in-out 0.9s;
    height: 33px !important;
    width: 90px !important;
    cursor: pointer;
  }
  .ourpartner {
    margin-top: 200px;
    margin-bottom: 40px;
    background-color: #f8f8f8;
    padding: 12px;
    border-radius: 20px;
  }
  .hero_groups {
    justify-content: start;
    bottom: -15% !important;
  }
  .ourwork2 img {
    width: 100%;
  }
  .ourwork2_1 img {
    width: 100%;
  }
  .ourwork4 img {
    width: 100%;
  }
  .ourwork5_1 img {
    width: 100%;
  }
  .herotopimg1 {
    position: absolute;
    left: -6% !important;
    top: -22px;
    width: 60px !important;
    height: 60px !important;
  }
  .transformingidea {
    width: 40px !important;
    height: 40px !important ;
  }
  .herotopimg2 {
    top: -22px;
    width: 60px !important;
    height: 60px !important;
    position: absolute;
    right: -6% !important;
  }
  .footer button {
    margin: 20px 0px;
    padding: 12px;
    width: 100%;
    border-radius: 110px;
    border: 1px solid #1b1464;
    color: white;
    background: #1b1464;
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    text-align: center;
  }
  .footerVelox img {
    width: 40% !important;
  }
  .footerVelox p {
    padding-top: 30px;
    margin: 0 auto;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }
  .contactDetail h3 {
    font-family: Montserrat;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }
  .contactDetail h2 {
    font-family: Montserrat;
    font-size: 16px !important;
    font-weight: 700;
    line-height: 24px !important;
    text-align: left;
  }
}

/* Media queries for 500px */
@media (max-width: 500px) {
  .ourteamCards {
    flex: 0 0 100%;
  }
  .ourTechPartners1,
  .ourTechPartners2 {
    flex-wrap: wrap;
    padding: 4px 0px;
    width: 100% !important;
  }
  .ourTechPartners {
    width: 30%;
  }
  .ourTechPartners2 {
    gap: 10px;
    display: flex;
    justify-content: center;
    padding: 20px 0px;
    width: 80%;
    margin: 0 auto;
  }
  .footerVelox {
    padding: 30px;
    text-align: center;
    border-radius: 40px;
    background: #f8f8f8;
  }
  .footerVelox p {
    padding-top: 40px;
    margin: 0 auto;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: center;
  }
  /* gallery  */
  .gallerytopbtns,
  .gallerymiddlebtns,
  .gallerybottombtns {
    flex-wrap: wrap;
    margin-bottom: 0px !important;
  }
  .gallerytopbtns button,
  .gallerymiddlebtns button,
  .gallerybottombtns button {
    text-align: center !important;
    display: flex;
    justify-content: center;
    align-items: center !important;
    width: 48% !important;
    flex-wrap: wrap;
  }
  .galleryContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }
  .gallerytop h1 {
    width: 100% !important;
    margin: 0 auto;
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
    text-align: center;
  }
  .gallerytop p {
    width: 100% !important;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }
  .gallerytop {
    text-align: center;
    border-radius: 50px;
    padding: 90px 40px;
    color: white;
    margin-bottom: 50px;
  }
}

/* Media queries for 450px */
@media (max-width: 450px) {
  .ourpartner {
    margin-top: 220px;
    margin-bottom: 40px;
    background-color: #f8f8f8;
    padding: 12px;
    border-radius: 20px;
  }
  .hero_groups {
    justify-content: start;
    bottom: -23% !important;
  }
  .hero-container {
    width: 100%;
    height: 770px;
  }
  .ourteamCards {
    flex: 0 0 100% !important;
    width: 100% !important;
  }
}
/* Media queries for 420px */
@media (max-width: 420px) {
  .herotopimg1 {
    left: -3% !important;
    top: -40px;
    width: 60px !important;
    height: 60px !important;
  }
  .transformingidea {
    width: 40px !important;
    height: 40px !important ;
  }
  .herotopimg2 {
    top: -30px;
    width: 60px !important;
    height: 60px !important;
    position: absolute;
    right: -2% !important;
  }
  .ourpartnerslogos {
    transition: all ease-in-out 0.9s;
    height: 27px !important;
    width: 70px !important;
    cursor: pointer;
  }
}

@media (max-width: 344px) {
  .hero-heading {
    position: relative;
    font-size: 13px;
    font-weight: 800;
    line-height: 22.6px;
    text-align: center;
  }
  .herotopimg1 {
    left: 3%;
    top: -33%;
  }
  .herotopimg2 {
    top: -25px;
    right: 2% !important;
  }
  .hero-para {
    width: 100%;
    font-size: 13px;
    margin-top: 48px;
  }
  .counter .counters p {
    font-size: 10px;
    line-height: unset;
  }
  .counter .counters h2 {
    padding-bottom: 0;
    font-size: 25px;
  }
  .aboutUs p {
    font-size: 12px;
  }
  .cards {
    padding: 40px 0;
  }
  .card3 button {
    left: 15%;
    right: 15%;
  }
  .footerVelox p {
    /* padding-top: 40px; */
    /* margin: 0 auto; */
    /* width: 100%; */
    font-size: 14px;
    font-weight: 500;
    /* line-height: 27px; */
    text-align: center;
  }
  .footer button {
    font-size: 11px;
  }
  .modelTopBarright p {
    font-size: 13px;
  }
  .modelTopBarright button {
    font-size: 13px;
  }
}

/*  */
@media (max-width: 375px) {
  .hero-heading {
    position: relative;
    font-size: 13px;
    font-weight: 800;
    line-height: 22.6px;
    text-align: center;
  }
  .herotopimg1 {
    left: 3%;
    top: -33%;
  }
  .herotopimg2 {
    top: -25px;
    right: 2% !important;
  }
  .hero-para {
    width: 100%;
    font-size: 13px;
    margin-top: 48px;
  }
  .counter .counters p {
    font-size: 10px;
    line-height: unset;
  }
  .counter .counters h2 {
    padding-bottom: 0;
    font-size: 25px;
  }
  .aboutUs p {
    font-size: 12px;
  }
  .cards {
    padding: 40px 0;
  }
  .card3 button {
    left: 15%;
    right: 15%;
  }
  .footerVelox p {
    /* padding-top: 40px; */
    /* margin: 0 auto; */
    /* width: 100%; */
    font-size: 14px;
    font-weight: 500;
    /* line-height: 27px; */
    text-align: center;
  }
  .footer button {
    font-size: 11px;
  }
  .modelTopBarright p {
    font-size: 13px;
  }
  .modelTopBarright button {
    font-size: 13px;
  }
}
